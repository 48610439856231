<template>
  <div class="q-pa-xl">
    <center>
      <!-- retry every 10 seconds -->
      <meta http-equiv="refresh" content="30;URL='/'" />
      <div class="fixed-center">
        <hi-logo size="xxl" color="white" />
        <h5><b>Cannot connect to back-end</b></h5>
        <h6>retrying in {{ time }} seconds</h6>
      </div>
    </center>
  </div>
</template>
<script>
export default {
  name: "Error",

  mounted() {
    if (!this.timer) {
      // hide /error url
      history.pushState({}, null, "/");
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        } else {
          clearInterval(this.timer);
          document.location = "/";
          console.log("RELOAD HOME ", document);
          this.reset();
        }
      }, 1000);
    }
  },

  data() {
    return {
      time: 30,
      timer: null,
    };
  },
};
</script>
<style>
body {
  background-color: red;
  color: white;
}
</style>